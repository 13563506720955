// Shippio theme colors
$shippio-blue: #000665;
$shippio-dark-blue: rgb(0, 4, 70);
$shippio-green: #38c734;
$shippio-light-blue: #5a83ec;

// UI colors
$light-blue: #e5eaf7;
$grey: #8f8f8f;
$light-grey: #f8f8f8;
$light-black: #3a3e46;
$black: #000000;
$light-grey-blue: #e8e9f1;
$text-dark-grey: #a1a1a1;
$blue-grey: #69739b;
$dark-blue-grey: #8a8f9c;
$white: #ffffff;
$red: #f44336;

// Other UI
$read-only-grey: #6c6c6c;
$divider: rgba(0, 0, 0, 0.1);

// Design System Colors
// https://www.figma.com/file/8P3qZ8wi4oNBrFP9GoFpr5/Shippio---Design-System-file?node-id=411%3A2

// Partner Colors
$partner-orange-1: #ffe1bf;
$partner-orange-5: #ff8600;
